import PropTypes from 'prop-types'

interface SelectOption {
    label: string
    // eslint-disable-next-line
    data: any
}

export interface InputProps {
    name: string
    placeholder: string
    label?: string
    options?: SelectOption[]
    tooltip?: string
    // eslint-disable-next-line
    [propName: string]: any
    // eslint-disable-next-line
    onUpdate?: (data: any) => void
    setRef?: (ref: React.MutableRefObject<null>) => void
    hideLabel?: boolean
    noMargin?: boolean
    darkTheme?: boolean
    height?: number
    dataCy?: string
    parentError?: string
    disableBorderRadiusBottom?: boolean
}

export const InputPropTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.any,
    tooltip: PropTypes.string,
    onUpdate: PropTypes.func,
    setRef: PropTypes.func,
}
